.reviewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.reviewContainer__layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.reviewTitleText__layout {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reviewTitleText {
  font-size: 34px;
  font-weight: 600;
}

.reviewTitleSubText {
  font-size: 25px;
  font-weight: 400;
  color: #33312a;
  margin-top: 1%;
}

.reviewImage {
  width: 100%;
}
